import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import { setAuthTokens, getAuthorizationState, resetAuth, setIdpOptions, setRejectedFrom, parseIdpOptions, getLastSignInAttemptType, setLastSignInAttemptType } from './AppSlice';
import { apiUrl } from './AppSlice';

// TODO: read ID & secret from file or other source, don't embed in code (Possibly configurable to allow different clients or specific to customer).
const clientId = "c5274903-e355-453a-af04-b5289345b969";
const clientSecret = "02cfa90c-ad71-43fc-8405-f53582abcb76";

// Requests access to 'portal.readwrite' (RBP). 'offline_access' requests a refresh token also. Authorised scopes may be lesser depeneding on user role.
const clientScope = "portal.readwrite offline_access openid";
export const VALID_PORTAL_ROLES = ["portal.user", "portal.admin"];

const tokenUrl = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_TOKEN_URL : window.TOKEN_URL;

const autoRefreshHeadStart = 300;

// User token in session storage - contains user details
function deleteUserToken() {
    if ('token' in sessionStorage)
        sessionStorage.removeItem('token');

    // Clear user cookie also
    // This depends on cookie not being set as HttpOnly. If it ever does then would require a logout call to the back-end to clear it.
    document.cookie = "user=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}

function setUserToken(userToken) {
    if (userToken) {
        sessionStorage.setItem('token', JSON.stringify(userToken));
    }
}

function getUserToken() {
    if ('token' in sessionStorage) {
        const tokenString = sessionStorage.getItem('token');
        const userToken = JSON.parse(tokenString);

        return userToken;
    }

    return null;
}

// Get user info from 'user' cookie set by back-end when using cookie auth
export function getCookieUserTokens() {
    const key = "user";
    const cookie = document.cookie.split("; ").filter(c => c.startsWith(key));

    if (cookie.length > 0) {
        const userstr = cookie[0].slice(key.length + 1);
        if (userstr) {
            const userToken = JSON.parse(decodeURIComponent(userstr));

            for (const key in userToken)
                if (!key.includes("roles") && userToken[key].length === 1)
                    userToken[key] = userToken[key][0];

            return userToken;
        }

    }
    return undefined;
}

function getLocalTokens() {
    const tk = ["accessToken", "refreshToken", "tokenExpires"];

    if (window.localStorage.length > 0) {

        const ret = tk.reduce((acc, curr) => {
            if (curr in window.localStorage) {
                if (curr === "tokenExpires")
                    acc[curr] = parseInt(window.localStorage.getItem(curr));
                else
                    acc[curr] = window.localStorage.getItem(curr);
            }

            return acc;
        }, {})

        return ret;

    } else
        return null;
}
function setLocalTokens(tokens) {
    deleteLocalTokens();
    window.localStorage.setItem("accessToken", tokens.accessToken);
    window.localStorage.setItem("refreshToken", tokens.refreshToken);
    window.localStorage.setItem('tokenExpires', tokens.tokenExpires);
}
function deleteLocalTokens() {
    if ("accessToken" in window.localStorage)
        window.localStorage.removeItem("accessToken");
    if ("refreshToken" in window.localStorage)
        window.localStorage.removeItem("refreshToken");
    if ('tokenExpires' in window.localStorage)
        window.localStorage.removeItem('tokenExpires');
}
function setClientToken(tokens) {
    deleteClientTokens();
    window.localStorage.setItem("clientAccessToken", tokens.accessToken);
    window.localStorage.setItem("clientTokenExpires", tokens.tokenExpires);
}
function deleteClientTokens() {
    if ("clientAccessToken" in window.localStorage)
        window.localStorage.removeItem("clientAccessToken");
    if ("clientTokenExpires" in window.localStorage)
        window.localStorage.removeItem("clientTokenExpires");
}

function setRequirePasswordChange(token) {
    if (token && token.requirePasswordChange && token.requirePasswordChange !== undefined)
        window.localStorage.setItem("requirePasswordChange", token.requirePasswordChange);
}

function deleteRequirePasswordChange() {
    if ("requirePasswordChange" in window.localStorage)
        window.localStorage.removeItem("requirePasswordChange");
}

// Basic API request to get if is authenticated with API. Simplest request with minimal impact.
const isAuthenticated = () => {
    return new Promise((resolv, reject) => {
        const dispatch = useDispatch();

        fetch(`${apiUrl}/isAuthenticated`, {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded'
            })
        }).then(response => {

            // Set IDP options from response headers. This is necessary to show the Login form in case the user is not yet authenticated.
            dispatch(setIdpOptions(parseIdpOptions(response)));

            if (response.status === 401) {
                reject(response)
            }

            resolv(response)
        })

    })
}


// Fetch new tokens for a new user auth with username/password provided from login form
export const fetchNewTokens = async (newUser) => {

    if (!newUser) {
        return {
            error: "No user auth supplied"
        };
    }

    const response = await fetchNewTokenPasswordGrant(newUser);

    if (!response.ok) {
        if (response.json)
            return response.json();
        else
            return response;
    }

    return response.json().then(parseTokens);
}

// Fetch access token with Resource Owner Password Credentials
//
// Flow:
//      Login form takes username/password, request tokens with client ID, secret, username & password. Authorisation on server will be based on
//      the roles for authenticated user and the scopes requested. Lower scopes than requested may be returned if not authorised for full scopes.
//
//      Note, this is a legacy auth in OAuth. Preferred flow is authorisation code or similar with a redirect to an external ID server login
//      and/or consent to authenticate and fetch identity.
async function fetchNewTokenPasswordGrant(userToken) {
    return await fetch(`${tokenUrl}/token`, {
        method: "POST",
        headers: new Headers({
            "Content-Type": "application/x-www-form-urlencoded"
        }),
        body: new URLSearchParams({
            "username": `${userToken.username}`,
            "password": `${userToken.password}`,
            "grant_type": "password",
            "client_id": clientId,
            "client_secret": clientSecret,
            "scope": clientScope
        })
    }).catch((err) => {
        return { error: err };
    });
}

// Fetch access token with Client Credentials
//
// Flow:
//      No user authentication, simply request a token with client ID & secret. Authorisation on server may be limited to non-user methods.
//
async function fetchNewTokenClientCredentialsGrant() {
    return await fetch(`${tokenUrl}/token`, {
        method: "POST",
        headers: new Headers({
            "Content-Type": "application/x-www-form-urlencoded"
        }),
        body: new URLSearchParams({
            "grant_type": "client_credentials",
            "client_id": clientId,
            "client_secret": clientSecret,
            "scope": "api"
        })
    }).catch((err) => {
        return { error: err };
    });
}

// Fetch new access token with refresh token
//
// Flow:
//      existing refresh token, request new access token
//
async function fetchNewTokenRefreshTokenGrant(refreshToken) {
    return await fetch(`${tokenUrl}/token`, {
        method: "POST",
        headers: new Headers({
            "Content-Type": "application/x-www-form-urlencoded"
        }),
        body: new URLSearchParams({
            "refresh_token": refreshToken,
            "grant_type": "refresh_token",
            "client_id": clientId,
            "client_secret": clientSecret
        })
    }).catch((err) => {
        return { error: err };
    });
}

async function fetchUserInfo(accessToken) {

    return await fetch(`${tokenUrl}/userinfo`, {
        method: "GET",
        headers: new Headers({
            "Content-Type": "application/x-www-form-urlencoded",
            "Authorization": `Bearer ${accessToken}`
        })
    }).catch((err) => {
        return { error: err };
    });
}

export const getUserInfo = async () => {

    // Can't fetch user info without an access token
    const accessToken = window.localStorage.getItem("accessToken");
    if (!accessToken)
        return { error: "No access token" };

    const response = await fetchUserInfo(accessToken);

    if (!response.ok)
        if (response.json)
            return response.json();
        else
            return response;

    const json = await response.json();

    const userinfo = parseUserInfo(json);
    if (userinfo)
        setUserToken(userinfo);

    return userinfo;
}


export const fetchNewClientToken = async () => {

    const response = await fetchNewTokenClientCredentialsGrant();

    if (!response.ok)
        if (response.json)
            return response.json();
        else
            return response;

    return response.json().then(parseClientToken);
}

async function refreshTokens(tokens) {

    if (!tokens.refreshToken) {
        return { error: "No refresh token" };
    }
    const response = await fetchNewTokenRefreshTokenGrant(tokens.refreshToken);

    if (!response.ok)
        return { error: "Failed to get new access token from refresh token" };

    return response.json().then(parseTokens);

}


function parseTokens(resp) {
    return {
        "accessToken": resp.access_token,
        "refreshToken": resp.refresh_token,
        "tokenExpires": (resp.expires_in && resp.expires_in >= 0 ?
            Math.floor(Date.now() / 1000) + resp.expires_in
            : 0),
        "scope": resp.scope,
        "requirePasswordChange": resp.require_password_change,
        "authorizationStatus": true
    }
}

function parseClientToken(resp) {
    return {
        "accessToken": resp.access_token,
        "tokenExpires": (resp.expires_in && resp.expires_in >= 0 ?
            Math.floor(Date.now() / 1000) + resp.expires_in
            : 0),
        "tokenType": resp.token_type,
        "scope": resp.scope
    }
}

function parseUserInfo(resp) {
    return {
        "username": resp.preferred_username,
        "name": resp.name,
        "email": resp.email,
        "roles": resp.role
    }
}

function wipeAllLocalData() {
    deleteLocalTokens();
    deleteRequirePasswordChange();
    deleteUserToken();
    rejectAuth();
}

function rejectAuth() {
    ApiAuthProvider.isAuthenticated = false;
    ApiAuthProvider.authorizationStatus = false;
    ApiAuthProvider.cookieAuth = false;
    ApiAuthProvider.requestStatus = 'error';
    ApiAuthProvider.user = null;
}

const ApiAuthProvider = {
    isAuthenticated: false,
    authorizationStatus: false,
    cookieAuth: null,
    requestStatus: '',
    callback: null,
    user: null,
    signin: async (newUser) => {
        return new Promise(async (resolve, reject) => {
            ApiAuthProvider.requestStatus = 'pending';
            return fetchNewTokens(newUser).then(async (resp) => {

                if (!resp.error) {
                    ApiAuthProvider.isAuthenticated = true;
                    ApiAuthProvider.authorizationStatus = true;
                    ApiAuthProvider.cookieAuth = false;
                    ApiAuthProvider.requestStatus = 'fullfilled';

                    setLocalTokens(resp);
                    setRequirePasswordChange(resp);

                    const user = await getUserInfo();
                    if (user?.username)
                        console.log(`Username: ${user.username}`);

                    if (!user?.roles.some(role => VALID_PORTAL_ROLES.includes(role))) {
                        wipeAllLocalData();
                        reject(resp);
                    }

                    ApiAuthProvider.user = user;

                    ApiAuthProvider.autoRefreshTokens(resp);

                    resolve({
                        ...resp,
                        ...user
                    });
                } else {
                    wipeAllLocalData();
                    reject(resp);
                }

                if (ApiAuthProvider.callback)
                    ApiAuthProvider.callback(resp);
            })
        });
    },
    refreshToken: async (tokens) => {
        return new Promise(async (resolve, reject) => {
            ApiAuthProvider.requestStatus = 'pending';
            return refreshTokens(tokens).then(async (resp) => {

                if (!resp.error) {
                    ApiAuthProvider.isAuthenticated = true;
                    ApiAuthProvider.authorizationStatus = true;
                    ApiAuthProvider.cookieAuth = false;
                    ApiAuthProvider.requestStatus = 'fullfilled';

                    setLocalTokens(resp);

                    const user = await getUserInfo();
                    if (user?.username)
                        console.log(`Username: ${user.username}`);

                    if (!user?.roles.some(role => VALID_PORTAL_ROLES.includes(role))) {
                        wipeAllLocalData();
                        reject(resp);
                    }
                    ApiAuthProvider.user = user;

                    resolve({
                        ...resp,
                        ...user
                    });
                } else {
                    wipeAllLocalData();
                    reject(resp);
                }

                if (ApiAuthProvider.callback)
                    ApiAuthProvider.callback(resp);
            })
        });

    },
    autoRefreshTokens: async (newT) => {

        const deadline = (newT.tokenExpires - Math.floor(Date.now() / 1000) - autoRefreshHeadStart) * 1000;

        setTimeout(async () => {
            ApiAuthProvider.refreshToken(newT).then((r) => {
                ApiAuthProvider.autoRefreshTokens(r);
            }).catch((err) => {
                if (ApiAuthProvider.callback)
                    ApiAuthProvider.callback(err);
            });
        }, deadline)
    },
    signout: async () => {

        return new Promise(() => {

            ApiAuthProvider.isAuthenticated = false;
            ApiAuthProvider.authorizationStatus = false;
            ApiAuthProvider.requestStatus = 'fullfilled';
            ApiAuthProvider.user = null;
            deleteLocalTokens()
            deleteRequirePasswordChange()
            deleteUserToken()

            if (ApiAuthProvider.callback)
                ApiAuthProvider.callback();
        })
    },
    signinNoUser: async () => {
        return new Promise(async (resolve, reject) => {
            ApiAuthProvider.requestStatus = 'pending';
            return fetchNewClientToken().then((resp) => {

                if (!resp.error) {
                    ApiAuthProvider.requestStatus = 'fullfilled';

                    setClientToken(resp);

                    resolve(resp);
                } else {
                    deleteClientTokens();
                    ApiAuthProvider.requestStatus = 'error';
                    reject(resp)
                }
            })
        });
    },
    signoutNoUser: async () => {
        deleteClientTokens();
    }
};


const AuthContext = React.createContext({});

function AuthProvider({ children }) {
    const dispatch = useDispatch();
    const lastSignInAttemptType = useSelector(getLastSignInAttemptType);
    const [contextVals, setContextVals] = useState({
        isAuthenticated: false,
        requestStatus: '',
        cookieAuth: null
    })

    const authState = useSelector(getAuthorizationState)

    const localToken = getLocalTokens();
    const cookieUserTokens = getCookieUserTokens();

    const getCurrentUser = () =>
        ApiAuthProvider.user;


    const signin = async (newUser) => {
        return ApiAuthProvider.signin(newUser).then((resp) => {
            dispatch(setAuthTokens({
                ...contextVals,
                ...resp,
                ...ApiAuthProvider.user,
                authorizationStatus: ApiAuthProvider.authorizationStatus,
                isAuthenticated: ApiAuthProvider.isAuthenticated,
                requestStatus: ApiAuthProvider.requestStatus,
                cookieAuth: false,
                rejectedFrom: ''
            })
            )
        });
    };

    const signinNoUser = () => {
        return ApiAuthProvider.signinNoUser().then((resp) => {
            setClientToken(resp);
            ApiAuthProvider.isAuthenticated = true;
            ApiAuthProvider.requestStatus = 'fullfilled';
            ApiAuthProvider.cookieAuth = false;
            ApiAuthProvider.user = null;
            dispatch(resetAuth({
                ...contextVals,
                ...resp,
                isAuthenticated: ApiAuthProvider.isAuthenticated,
                authorizationStatus: ApiAuthProvider.authorizationStatus,
                requestStatus: ApiAuthProvider.requestStatus,
                cookieAuth: false,
                rejectedFrom: ''
            }))
        });
    };

    const signoutNoUser = async () => {
        return ApiAuthProvider.signoutNoUser()
            .then(() => {

                ApiAuthProvider.isAuthenticated = false;
                ApiAuthProvider.requestStatus = 'fullfilled';
                ApiAuthProvider.cookieAuth = false;
                ApiAuthProvider.user = null;
                dispatch(resetAuth({
                    ...contextVals,
                    isAuthenticated: ApiAuthProvider.isAuthenticated,
                    authorizationStatus: ApiAuthProvider.authorizationStatus,
                    requestStatus: ApiAuthProvider.requestStatus,
                    cookieAuth: false,
                    rejectedFrom: ''
                }))
            });
    };

    const signout = async () => {
        return ApiAuthProvider.signout()
            .then(() => {

                ApiAuthProvider.isAuthenticated = false;
                ApiAuthProvider.requestStatus = 'fullfilled';
                ApiAuthProvider.cookieAuth = false;
                ApiAuthProvider.user = null;
                dispatch(resetAuth({
                    ...contextVals,
                    isAuthenticated: ApiAuthProvider.isAuthenticated,
                    authorizationStatus: ApiAuthProvider.authorizationStatus,
                    requestStatus: ApiAuthProvider.requestStatus,
                    cookieAuth: false,
                    rejectedFrom: ''
                }))
            });
    };

    const apiCallBack = (data) => {

        if (data && !data.error) {
            setLocalTokens(data)
            dispatch(setAuthTokens({
                ...contextVals,
                ...data,
                ...ApiAuthProvider.user,
                isAuthenticated: ApiAuthProvider.isAuthenticated,
                authorizationStatus: ApiAuthProvider.authorizationStatus,
                requestStatus: ApiAuthProvider.requestStatus,
                cookieAuth: false
            }));
        } else {
            deleteUserToken()
            deleteRequirePasswordChange()
            if (ApiAuthProvider.cookieAuth) {
                setRejectedFrom('aad')
            }
            dispatch(setAuthTokens({
                ...contextVals,
                "accessToken": '',
                "refreshToken": '',
                "tokenExpires": 0,
                "authorizationStatus": ApiAuthProvider.authorizationStatus,
                isAuthenticated: ApiAuthProvider.isAuthenticated,
                requestStatus: ApiAuthProvider.requestStatus,
                cookieAuth: false
            }));
        }
    }


    const initialisation = useRef(() => {
        if (ApiAuthProvider.callback === null)
            ApiAuthProvider.callback = apiCallBack

        ApiAuthProvider.user = getUserToken();

        if (ApiAuthProvider.requestStatus === '') {

            if (ApiAuthProvider.isAuthenticated === false) {
                if (localToken && localToken.refreshToken) {

                    // We have a refresh token, use it to fresh access token

                    ApiAuthProvider.requestStatus = 'pending';
                    ApiAuthProvider.refreshToken(localToken)
                        .then((resp => {
                            ApiAuthProvider.autoRefreshTokens(resp)
                            dispatch(setAuthTokens({
                                ...contextVals,
                                ...resp,
                                ...ApiAuthProvider.user,
                                isAuthenticated: ApiAuthProvider.isAuthenticated,
                                authorizationStatus: ApiAuthProvider.authorizationStatus,
                                requestStatus: ApiAuthProvider.requestStatus,
                                cookieAuth: false
                            }))
                        }));

                } else if (cookieUserTokens) {
                    setUserToken(cookieUserTokens)

                    // We have Cookie user token already in the session. Check if has appropriate roles. If not, reject auth.
                    if (!cookieUserTokens?.roles.some(role => VALID_PORTAL_ROLES.includes(role))) {
                        dispatch(setRejectedFrom('aad')).then(() => rejectAuth());
                    } else {
                        ApiAuthProvider.isAuthenticated = true;
                        ApiAuthProvider.authorizationStatus = true;
                        ApiAuthProvider.requestStatus = 'fullfilled';
                        ApiAuthProvider.cookieAuth = true;
                        ApiAuthProvider.user = cookieUserTokens;

                        dispatch(setAuthTokens({
                            ...contextVals,
                            ...cookieUserTokens,
                            isAuthenticated: ApiAuthProvider.isAuthenticated,
                            authorizationStatus: ApiAuthProvider.authorizationStatus,
                            requestStatus: ApiAuthProvider.requestStatus,
                            cookieAuth: ApiAuthProvider.cookieAuth
                        }))
                    }


                } else if (ApiAuthProvider.cookieAuth !== false) {

                    // We're in here if Cookie auth and no token yet, or not sure if Cookie auth (cookieAuth null)

                    ApiAuthProvider.isAuthenticated = false;
                    ApiAuthProvider.authorizationStatus = false;
                    ApiAuthProvider.requestStatus = 'pending';

                    dispatch(setAuthTokens({
                        ...contextVals,
                        ...localToken,
                        isAuthenticated: ApiAuthProvider.isAuthenticated,
                        authorizationStatus: ApiAuthProvider.authorizationStatus,
                        requestStatus: ApiAuthProvider.requestStatus,
                    }))

                    // needs a first request to check if back-end thinks already authenticated (SSO) and this will trigger back-end to set relevant
                    // user cookie which can be used to get user information

                    isAuthenticated().then(r => {
                        dispatch(setIdpOptions(parseIdpOptions(r)));

                        const newCookieTokens = getCookieUserTokens();
                        if (newCookieTokens) {
                            setUserToken(newCookieTokens)

                            // Reject user if missing portal roles.
                            if (!newCookieTokens?.roles.some(role => VALID_PORTAL_ROLES.includes(role))) {
                                dispatch(setRejectedFrom('aad')).then(() => rejectAuth());
                            } else {
                                ApiAuthProvider.isAuthenticated = true;
                                ApiAuthProvider.authorizationStatus = true;
                                ApiAuthProvider.requestStatus = 'fullfilled';
                                ApiAuthProvider.cookieAuth = true;
                                ApiAuthProvider.user = newCookieTokens;

                                dispatch(setAuthTokens({
                                    ...contextVals,
                                    ...newCookieTokens,
                                    isAuthenticated: ApiAuthProvider.isAuthenticated,
                                    authorizationStatus: ApiAuthProvider.authorizationStatus,
                                    requestStatus: ApiAuthProvider.requestStatus,
                                    cookieAuth: true,
                                    rejectedFrom: ''
                                }))
                            }
                        }

                    }).catch((r) => {
                        dispatch(setIdpOptions(parseIdpOptions(r)));

                        console.log("lastSignInAttemptType", lastSignInAttemptType);

                        if (lastSignInAttemptType === 'aad') {
                            dispatch(setRejectedFrom('aad'));
                        }

                        ApiAuthProvider.isAuthenticated = false;
                        ApiAuthProvider.authorizationStatus = false;
                        ApiAuthProvider.requestStatus = 'error';
                        ApiAuthProvider.cookieAuth = false;

                        console.log("auth error", r);
                        dispatch(resetAuth({
                            ...contextVals,
                            isAuthenticated: ApiAuthProvider.isAuthenticated,
                            authorizationStatus: ApiAuthProvider.authorizationStatus,
                            requestStatus: ApiAuthProvider.requestStatus,
                            cookieAuth: false
                        }))

                    });
                }
            }
        }
    })
    const refresh = useRef((tokens) => {
        if (tokens.isAuthenticated && !tokens.authorizationStatus && tokens.requestStatus !== "pending") {
            if (tokens.refreshToken) {

                ApiAuthProvider.refreshToken(tokens)
                    .then((resp => {
                        ApiAuthProvider.autoRefreshTokens(resp)

                        ApiAuthProvider.isAuthenticated = true;
                        ApiAuthProvider.requestStatus = 'fullfilled';
                        dispatch(setAuthTokens({
                            ...contextVals,
                            ...resp,
                            ...ApiAuthProvider.user,
                            isAuthenticated: ApiAuthProvider.isAuthenticated,
                            authorizationStatus: ApiAuthProvider.authorizationStatus,
                            requestStatus: ApiAuthProvider.requestStatus,
                            cookieAuth: false
                        }))
                    })).catch(() => {
                        ApiAuthProvider.isAuthenticated = false;
                        ApiAuthProvider.requestStatus = 'error';
                        dispatch(resetAuth({
                            ...contextVals,
                            isAuthenticated: ApiAuthProvider.isAuthenticated,
                            authorizationStatus: ApiAuthProvider.authorizationStatus,
                            requestStatus: ApiAuthProvider.requestStatus,
                            cookieAuth: false
                        }))
                    })
            }
        }
    })


    useMemo(() => {
        if (contextVals.requestStatus !== "pending") {
            if (!contextVals.isAuthenticated) {
                initialisation.current();

            }

        }
    }, [initialisation, contextVals]);

    useEffect(() => {

        setContextVals(c => c = {
            ...c,
            ...authState
        })

        if (ApiAuthProvider.requestStatus !== "pending") {
            if (!authState.authorizationStatus && authState.isAuthenticated) {
                refresh.current(authState);
            }
        }

    }, [authState, refresh])

    return <AuthContext.Provider value={{ contextVals, signin, signinNoUser, signout, signoutNoUser, getCurrentUser }}>{children}</AuthContext.Provider>;
}

export function useAuth() {
    return React.useContext(AuthContext);
}

export function usingTokenLogin() {
    const tokens = getLocalTokens();

    return (tokens && tokens?.accessToken && tokens?.refreshToken);
}

export function getAccessToken() {
    const tokens = getLocalTokens();

    return tokens?.accessToken;
}

export function RequireAuth({ children }) {

    const authState = useSelector(getAuthorizationState)
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // If being redirected from aad, can assume we've been rejected.
    if (window.location.search.includes('from=aad')) {
        dispatch(setLastSignInAttemptType('aad'))
        dispatch(setRejectedFrom('aad'))
    }

    const onAuthNavigate = useCallback(() => {
        navigate('/login', { state: { from: location.pathname } });
    }, [navigate, location.pathname])

    useCallback(() => {
        if (authState.requestStatus === 'error') {
            onAuthNavigate();
        }
    }, [authState, onAuthNavigate]);

    // Show login form if not authenticated or in case of authentication error.
    if ((!authState.isAuthenticated && authState.authorizationStatus === null && authState.requestStatus === 'fulfilled') || authState.requestStatus === 'error')
        return <Navigate to="/login" state={{ from: location }} replace />;

    return children;
}
export { AuthProvider };
