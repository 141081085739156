import { FC } from "react";
import "./SwitchButton.scss";

interface ToggleSwitchProps {
    changeHandler: () => void;
    isActive: boolean;
}

const ToggleSwitch: FC<ToggleSwitchProps> = ({ changeHandler, isActive }) => (
    <span
        className="toggle-switch-container"
    >
        <input
            type="checkbox"
            checked={isActive}
            id="toggle-switch"
            onChange={changeHandler}
            hidden={true}
        />
        <label
            htmlFor="toggle-switch"
            className={`toggle-switch label`}
        ></label>

    </span>
);

export default ToggleSwitch;