import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiUrl, baseFetchFromServer } from "../AppSlice";

const initialState = {
    isLicensed: true,
    isLicensedRequestComplete: false,
};

export const fetchIsLicensed = createAsyncThunk(`fetchIsLicensed`,
    async (params, thunkApi) => {

        const response = await thunkApi.dispatch(baseFetchFromServer({
            input: `${apiUrl}/portal/licence/isLicensed`,
            init: {
                method: 'GET',
                headers: new Headers({
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            }
        }));
        if (response.meta.requestStatus === 'rejected') {
            return thunkApi.rejectWithValue({ status: response.payload?.toString() ?? '' });
        }
        const resolvedResponse = response.payload;
        if (!(resolvedResponse.ok && resolvedResponse.text)) {
            return thunkApi.rejectWithValue({ status: resolvedResponse.status?.toString() ?? '' });
        }

        return resolvedResponse.text;
    }
);

export const licenceSlice = createSlice({
    name: "licence",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchIsLicensed.pending, (state, action) => {
            state.isLicensedRequestComplete = false;
        });
        builder.addCase(fetchIsLicensed.fulfilled, (state, action) => {
            if (action.payload.length > 0) {
                const js = JSON.parse(action.payload);
                state.isLicensed = js.data.isLicensed;
            }
            state.isLicensedRequestComplete = true;
        });
        builder.addCase(fetchIsLicensed.rejected, (state, action) => {
            state.isLicensedRequestComplete = true;
        });
    }
});

export const getIsLicensed = (state) => state.licence.isLicensed;
export const getFetchIsLicensedComplete = (state) => state.licence.isLicensedRequestComplete;

export default licenceSlice.reducer;
