import { BookingSettings, Desk, DeskStatus, WorkHours } from "../types";
import { checkIntervalOverlap, convertMinutesToHHMM, createDateObject, formatTime24H, getCurrentDate, getCurrentTime, getIntervalInMinutes } from "./datetimeUtils";

const getDeskStatus = (bookingOptions: BookingSettings, desk: Desk): DeskStatus => {

    const weekdays = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];

    // Check if target period fits within desk working hours.
    const bookingSettings = bookingOptions;
    let targetIntervalStart: Date = createDateObject(bookingSettings.date, bookingSettings.startTime) ?? new Date();
    let targetIntervalEnd: Date = createDateObject(bookingSettings.date, bookingSettings.endTime) ?? new Date();

    const targetIntervalWeekday = weekdays[targetIntervalStart.getDay()];

    const deskWorkingHours: Partial<WorkHours> = Object.keys(desk.effectiveWorkHours).length > 0
        ? desk.effectiveWorkHours
        : desk.workHours;

    const noWorkingHoursForThisDay = Object.keys(deskWorkingHours).length === 0 || deskWorkingHours[targetIntervalWeekday] === undefined;

    const targetIntervalDay = targetIntervalWeekday.toLowerCase();
    const targetWorkingHours = deskWorkingHours[targetIntervalDay];

    let targetIntervalWithinBookingHours = false;

    // Check desk profile rules

    // Check if desk can be booked
    if (!desk.profile.settings.create) {
        return {
            available: false,
            outsideWorkingHours: false,
            bookedBy: '',
            date: bookingSettings.date.replace(/-/g, "/"),
            startTime: '',
            endTime: '',
            unknown: false,
            errorMessage: "Unavailable"
        };
    }

    // Check maximum booking duration
    const bookingDurationMinutes = getIntervalInMinutes(targetIntervalStart, targetIntervalEnd);
    if (bookingDurationMinutes > desk.profile.settings.maximumExtensionMinutes) {
        return {
            available: false,
            outsideWorkingHours: false,
            bookedBy: '',
            date: bookingSettings.date.replace(/-/g, "/"),
            startTime: '',
            endTime: '',
            unknown: false,
            errorMessage: `This desk cannot be booked longer than ${convertMinutesToHHMM(desk.profile.settings.maximumExtensionMinutes)}h`
        };
    }

    // Check maximum days in advance
    const maximumAdvancedBookingDaysMilliseconds = desk.profile.settings.maximumAdvancedBookingDays * 24 * 60 * 60 * 1000;
    if ((targetIntervalStart.getTime() - new Date().getTime()) > maximumAdvancedBookingDaysMilliseconds) {
        return {
            available: false,
            outsideWorkingHours: false,
            bookedBy: '',
            date: bookingSettings.date.replace(/-/g, "/"),
            startTime: '',
            endTime: '',
            unknown: false,
            errorMessage: `This desk cannot be booked more than ${desk.profile.settings.maximumAdvancedBookingDays} days in advance.`
        };
    }

    // Check if there are available working hours    
    if (noWorkingHoursForThisDay) {
        return {
            available: false,
            outsideWorkingHours: true,
            bookedBy: '',
            date: bookingSettings.date.replace(/-/g, "/"),
            startTime: '',
            endTime: '',
            unknown: false
        };
    }

    if (targetWorkingHours && targetWorkingHours.start && targetWorkingHours.end) {

        const workingHoursStart = createDateObject(bookingSettings.date, targetWorkingHours.start) ?? new Date();
        const workingHoursEnd = createDateObject(bookingSettings.date, targetWorkingHours.end) ?? new Date();

        // If all day option is set, then target interval is the same as the desks' working hours.
        if (bookingSettings.allDay) {
            if (bookingSettings.date === getCurrentDate()) {
                targetIntervalStart = createDateObject(bookingSettings.date, getCurrentTime(1)) ?? new Date();
                targetIntervalEnd = createDateObject(bookingSettings.date, targetWorkingHours.end) ?? new Date();
            } else {
                targetIntervalStart = createDateObject(bookingSettings.date, targetWorkingHours.start) ?? new Date();
                targetIntervalEnd = createDateObject(bookingSettings.date, targetWorkingHours.end) ?? new Date();
            }

        }

        // Determine if target interval is within working hours.
        targetIntervalWithinBookingHours = workingHoursStart <= targetIntervalStart && workingHoursEnd >= targetIntervalEnd;

        // Desk unavailable target interval falls outside set working hours.
        if (!targetIntervalWithinBookingHours)
            return {
                available: false,
                outsideWorkingHours: true,
                bookedBy: '',
                date: bookingSettings.date.replace(/-/g, "/"),
                startTime: targetWorkingHours.start,
                endTime: targetWorkingHours.end,
                unknown: false
            };

        // Check if there are any bookings that overlap with the target time interval.
        const bookingsForTargetInterval = desk.bookings.filter((booking) =>
            checkIntervalOverlap(targetIntervalStart, targetIntervalEnd, new Date(booking.startTime), new Date(booking.endTime)));

        if (bookingSettings.allDay && bookingsForTargetInterval.length === 0)
            return {
                available: true,
                outsideWorkingHours: false,
                bookedBy: "",
                date: bookingSettings.date.replace(/-/g, "/"),
                startTime: bookingSettings.date === getCurrentDate() ? getCurrentTime(1) : targetWorkingHours.start,
                endTime: targetWorkingHours.end,
                unknown: false
            };

        if (bookingsForTargetInterval.length > 0) {

            return {
                available: false,
                outsideWorkingHours: false,
                bookedBy: bookingsForTargetInterval[0].organiser.name,
                date: bookingSettings.date.replace(/-/g, "/"),
                startTime: targetWorkingHours.start,
                endTime: targetWorkingHours.end,
                bookedFrom: formatTime24H(new Date(bookingsForTargetInterval[0].startTime)),
                bookedUntil: formatTime24H(new Date(bookingsForTargetInterval[0].endTime)),
                unknown: false

            };
        } else {
            // If target interval is within working hours and isn't overlapped by existing bookings, then show desk as available.
            return {
                available: true,
                outsideWorkingHours: false,
                bookedBy: "",
                date: bookingSettings.date.replace(/-/g, "/"),
                startTime: targetWorkingHours.start,
                endTime: targetWorkingHours.end,
                unknown: false
            };
        }
    } else {
        // If none of these conditions are met, status is unknown
        return {
            available: false,
            outsideWorkingHours: false,
            bookedBy: "",
            date: bookingSettings.date.replace("-", "/"),
            startTime: "",
            endTime: "",
            unknown: true
        };
    }

};

export {
    getDeskStatus
};