import './Header.scss';
import "bootstrap/dist/css/bootstrap.min.css";
import { useContext } from 'react';
import { ViewportContext } from '../../contexts/ViewportContext';
import { useNavigate } from 'react-router-dom';
import { ReservaLogo as Logo } from '../../assets/logo';
import { HeaderHelpIcon, HeaderBellIcon, HeaderLogoutIcon } from '../../assets/icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useAppSelector } from '../../hooks';
import { getAuthorizationState, getRejectedFrom, setRejectedFrom } from '../../AppSlice';
import { useAuth } from "../../Auth";
import { useDispatch } from 'react-redux';

function Header() {

    const { isMobile } = useContext(ViewportContext);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const authState = useAppSelector(getAuthorizationState);
    const rejectedFrom = useAppSelector(getRejectedFrom);
    const auth = (useAuth() as any); // TODO: avoids type warning as Auth.js isn't typescript. Fix by converting to TS.

    const logout = () => {
        auth.signout();
        if (authState.identity_provider === 'aad' || rejectedFrom === 'aad') {
            if (rejectedFrom === 'aad') {
                dispatch(setRejectedFrom(''));
            }

            window.location.assign("/.auth/logout?post_logout_redirect_uri=/");
        } else {
            navigate('/login');
        }
    };

    const DesktopHeader = () => <header>
        <div className={"header-content"}>
            <div>
                <Logo className={"cursor-pointer logo"} />
            </div>
            <div className={"reserva-header-tools"}>
                <button>
                    <HeaderBellIcon />
                </button>
                <button>
                    <HeaderHelpIcon />
                </button>
                <OverlayTrigger
                    placement={'bottom'}
                    key={"Logout"}
                    overlay={<Tooltip className={"sidenav-tooltip"} id="button-tooltip">Log out: {authState.username}</Tooltip>}
                >
                    <button onClick={() => logout()} >
                        <HeaderLogoutIcon />
                    </button>
                </OverlayTrigger>
            </div>
        </div>
    </header >;

    const MobileHeader = () => <header>
        <div className={"header-content mobile"}>
            <div>
                <Logo className={"cursor-pointer logo"} />
            </div>

            <div className={"reserva-header-tools"}>
                <button>
                    <HeaderBellIcon />
                </button>
                <button>
                    <HeaderLogoutIcon />

                </button>
            </div>
        </div>
    </header>;

    return (isMobile ? MobileHeader() : DesktopHeader());
}

export default Header;
