import { useEffect, useRef, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import './Login.scss';
import { NavLink } from "react-bootstrap";
import { ReservaLogo } from "../../assets/logo";
import { MsLoginLight } from "../../assets/icons";
import { useAuth } from "../../Auth";
import { fetchIsLicensed } from "../../store/licenceSlice";
import { useDispatch, useSelector } from "react-redux";
import { getIdpOptions, getRejectedFrom, setLastSignInAttemptType } from "../../AppSlice";
import Header from "../../components/Header/Header";

function Login() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [invalidAuth, setInvalidAuth] = useState(false)
    const [insufficientPermissions, setInsufficientPermissions] = useState(false)
    const [backendOnline, setBackendOnline] = useState(true)
    const auth = useRef(useAuth());
    const location = useLocation();
    const idpOptions = useSelector(getIdpOptions);
    const rejectedFrom = useSelector(getRejectedFrom);

    useEffect(() => {
        auth.current.signout();
    }, []);

    function validateForm() {
        return username.length > 0 && password.length > 0;
    }

    function handleSubmit(event) {
        event.preventDefault();
        const token = { username, password }

        auth.current.signin(token).then((r) => {
            dispatch(setLastSignInAttemptType('basic'))
            dispatch(fetchIsLicensed());
            setBackendOnline(true);
            if (window.localStorage.getItem("requirePasswordChange") === 'true') {
                navigate('/changePassword', { replace: true, state: { ...location.state, username, password, requireChange: true } })
            } else {
                const to = location.state?.from ? location.state.from : '/';
                navigate(to, { replace: true })
            }
        }).catch((err) => {
            if (err.error) {
                setBackendOnline(err.error === "invalid_grant")
                setInvalidAuth(true);
                setInsufficientPermissions(false);
                setUsername('');
                setPassword('');
            } else {
                setInsufficientPermissions(true);
                setInvalidAuth(false);
                setUsername('');
                setPassword('');
            }
        });
    }

    return (
        <div className="App">
            <Header />
            <div className={"reserva-login-container"}>
                <div className="reserva-login">
                    <ReservaLogo />
                    <Form onSubmit={handleSubmit} hidden={!(idpOptions.includes('local') || idpOptions.length === 0)}>
                        <Form.Group controlId="username">
                            <Form.Control
                                autoFocus
                                type="text"
                                value={username}
                                placeholder={"User Name"}
                                onChange={(e) => setUsername(e.target.value)}
                                isInvalid={invalidAuth || insufficientPermissions}
                            />
                        </Form.Group>
                        <Form.Group controlId="password">
                            <Form.Control
                                type="password"
                                value={password}
                                placeholder={"Password"}
                                onChange={(e) => setPassword(e.target.value)}
                                isInvalid={invalidAuth || insufficientPermissions}
                            />
                            <Form.Control.Feedback type="invalid" hidden={!invalidAuth}>
                                {backendOnline ? "Invalid credentials." : "We're having trouble logging in, please try again later."}
                            </Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid" hidden={!insufficientPermissions}>
                                {"This user lacks the necessary role to access Reserva Booking Portal."}
                            </Form.Control.Feedback>
                            <span className="invalid" hidden={rejectedFrom !== 'aad'} >
                                {"This Microsoft user lacks the necessary role to access Reserva Booking Portal."}
                            </span>
                        </Form.Group>
                        <NavLink onClick={() => navigate("/forgotPassword")}>
                            Forgot password?
                        </NavLink >
                        <Button variant="primary" size="lg" type="submit" disabled={!validateForm()}>
                            Connect
                        </Button>
                    </Form>
                    <div className="idp-login" hidden={!idpOptions.includes('aad')}>
                        <a href="/.auth/login/aad?post_login_redirect_uri=/?from=aad"><MsLoginLight /></a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;