import ReactDOM from 'react-dom/client';
import React from 'react';
import { ViewportProvider } from './contexts/ViewportContext';
import App from './App';
import { Provider } from 'react-redux';
import { store } from './store';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <ViewportProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </ViewportProvider>
  </React.StrictMode>
);