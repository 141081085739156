import { TimeRange } from "../types";

function convert24hTo12h(time24h: string): string {
    let [hours, minutes] = time24h.split(':');
    let period = '';

    if (parseInt(hours) >= 12) {
        period = 'pm';
        if (parseInt(hours) > 12) {
            hours = (parseInt(hours) - 12).toString();
        }
    } else {
        period = 'am';
        if (hours === '00') {
            hours = '12';
        }
    }

    // Strip leading zero for hours
    hours = parseInt(hours).toString();

    const time12h = `${hours}:${minutes}${period}`;

    return time12h;
}

function formatTime24H(date: Date): string {
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
}

function formatAMPM(date: Date) {
    var hours: any = date.getHours();
    var ampm: any = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12;
    var strTime = hours + ' ' + ampm;

    if (strTime === "12 pm")
        strTime = "Midday";

    return strTime;
}

function formatDateToDDMMYYYY(rawDate: Date) {
    const date = new Date(rawDate);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
}

function getDuration(startDateTime: Date, endDateTime: Date) {
    const timeDiff = Math.abs(startDateTime.getTime() - endDateTime.getTime());
    const hoursDiff = Math.floor(timeDiff / (1000 * 60 * 60));
    const minutesDiff = Math.floor((timeDiff / (1000 * 60)) % 60);

    let duration = `${hoursDiff.toString().padStart(2, '0')}:${minutesDiff.toString().padStart(2, '0')}`;
    if (hoursDiff < 10)
        duration = duration.slice(1);

    return duration;
}

function compareByDateTime(a: any, b: any) {
    const dateTimeA = new Date(a.startTime).getTime();
    const dateTimeB = new Date(b.startTime).getTime();

    if (dateTimeA < dateTimeB)
        return -1;

    if (dateTimeA > dateTimeB)
        return 1;

    return 0;
}

const getCurrentDate = (): string => {
    const today: Date = new Date();

    const year: number = today.getFullYear();
    const month: string = (today.getMonth() + 1).toString().padStart(2, '0');
    const day: string = today.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
};

// Return current time in a string formatted as hh:mm with optional minute increment. 
const getCurrentTime = (increment: number = 0): string => {
    const today: Date = new Date(new Date().getTime() + increment * 60000);

    const hours: number = today.getHours();
    const minutes: number = today.getMinutes();

    return `${hours}:${minutes.toString().padStart(2, '0')}`;
};

const getCurrentTimeAndNextHour = (intervalDurationMinutes?: number): TimeRange => {
    const now: Date = new Date();

    const currentMinutes: number = now.getMinutes();
    const remainingMinutes: number = 15 - (currentMinutes % 15);
    const adjustedNow: Date = new Date(now.getTime() + remainingMinutes * 60000);

    const currentHours: number = adjustedNow.getHours();
    const currentAdjustedMinutes: number = adjustedNow.getMinutes();

    const nextTime: Date = new Date(adjustedNow);
    if (intervalDurationMinutes) {
        nextTime.setMinutes(nextTime.getMinutes() + intervalDurationMinutes);
    } else {
        nextTime.setHours(currentHours + 1);
    }

    const currentTime: string = `${currentHours.toString().padStart(2, '0')}:${currentAdjustedMinutes.toString().padStart(2, '0')}`;
    const nextTimeHours: string = nextTime.getHours().toString().padStart(2, '0');
    const nextTimeMinutes: string = nextTime.getMinutes().toString().padStart(2, '0');
    const nextHourTime: string = `${nextTimeHours}:${nextTimeMinutes}`;

    return {
        currentTime,
        nextHourTime,
    };
};

function createDateObject(dateString: string, timeString: string): Date | null {
    const [year, month, day] = dateString.split('-').map(Number);
    const [hours, minutes] = timeString.split(':').map(Number);

    if (isNaN(day) || isNaN(month) || isNaN(year) || isNaN(hours) || isNaN(minutes)) {
        return null;
    }

    const jsMonth = month - 1;

    const resultDate = new Date(year, jsMonth, day, hours, minutes);

    if (isNaN(resultDate.getTime())) {
        return null;
    }

    return resultDate;
}

// Check if two Date intervals overlap either partially or completely.
function checkIntervalOverlap(
    intervalAStart: Date,
    intervalAEnd: Date,
    IntervalBStart: Date,
    IntervalBEnd: Date
): boolean {
    // Normalise dates
    const nIntervalAStart = new Date(intervalAStart);
    const nIntervalAEnd = new Date(intervalAEnd);
    const nIntervalBStart = new Date(IntervalBStart);
    const nIntervalBEnd = new Date(IntervalBEnd);

    // Check if the intervals overlap
    return nIntervalAStart <= nIntervalBEnd && nIntervalBStart <= nIntervalAEnd;
}

// Check if two date objects represent the same day, regardless of time.
function areDatesOnSameDay(dateA: Date, dateB: Date): boolean {
    return (
        dateA.getFullYear() === dateB.getFullYear() &&
        dateA.getMonth() === dateB.getMonth() &&
        dateA.getDate() === dateB.getDate()
    );
}

const getMonthName = (date: Date): string => date.toLocaleString('default', { month: 'long' });

const getMonthNameShort = (date: Date): string => date.toLocaleString('default', { month: 'short' });

function getWeekday(date: Date): string {
    const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const dayIndex = date.getDay();
    return weekdays[dayIndex];
}

function getWeekdayShort(date: Date): string {
    const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const dayIndex = date.getDay();
    return weekdays[dayIndex];
}

function isTimeBefore(timeA: string, timeB: string): boolean {
    if (timeA !== undefined && timeB !== undefined) {
        const date = new Date();
        const [hoursA, minutesA] = timeA.split(':').map(Number);
        const [hoursB, minutesB] = timeB.split(':').map(Number);

        date.setHours(hoursA, minutesA, 0, 0);
        const timeAInMilliseconds = date.getTime();

        date.setHours(hoursB, minutesB, 0, 0);
        const timeBInMilliseconds = date.getTime();

        return timeAInMilliseconds < timeBInMilliseconds;
    }

    return false;
}

function getIntervalInMinutes(startDate: Date, endDate: Date): number {
    const differenceInMilliseconds = endDate.getTime() - startDate.getTime();
    const differenceInMinutes = differenceInMilliseconds / (1000 * 60);
    return differenceInMinutes;
}

function convertMinutesToHHMM(totalMinutes: number): string {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}`;
}

export {
    convert24hTo12h,
    formatAMPM,
    formatDateToDDMMYYYY,
    getDuration,
    compareByDateTime,
    getCurrentDate,
    getCurrentTime,
    getCurrentTimeAndNextHour,
    createDateObject,
    checkIntervalOverlap,
    areDatesOnSameDay,
    getMonthName,
    getWeekday,
    getMonthNameShort,
    getWeekdayShort,
    isTimeBefore,
    getIntervalInMinutes,
    convertMinutesToHHMM,
    formatTime24H
};