import './BottomNav.scss';
import { Container, Col, Row, Button } from 'react-bootstrap';

import { NavLink } from 'react-router-dom';
import React from 'react';
import { IconNavDashboard, IconNavRooms, IconAdd, IconNavDesks, IconHelp } from '../../assets/icons';

const BottomNav = () => {
    return (
        <Container className={"bottom-nav"}>
            <Row>
                <Col>
                    <NavLink to="/">
                        <IconNavDashboard />
                    </NavLink>
                </Col>
                <Col>
                    <NavLink to="/rooms">
                        <IconNavRooms />
                    </NavLink>
                </Col>
                <Col>
                    <Button className="btn-add">
                        <IconAdd />
                    </Button>
                </Col>
                <Col>
                    <NavLink to="/desks">
                        <IconNavDesks />
                    </NavLink>
                </Col>
                <Col><IconHelp /></Col>
            </Row>
        </Container>
    );
};

export default BottomNav;