import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    baseFetchFromServer,
    noUserBaseFetchFromServer,
    apiUrl
} from '../../AppSlice';

const initialState = {
    changePasswordRequestComplete: false,
    resetPasswordRequestComplete: false
};

export const changeUserPassword = createAsyncThunk(`changeUserPassword`,
    async (params, thunkApi) => {
        const response = await thunkApi.dispatch(baseFetchFromServer({
            input: `${apiUrl}/portal/users/changePassword`,
            init: {
                method: 'POST',
                headers: new Headers({
                    "Content-Type": "application/json"
                }),
                body: JSON.stringify(params.body)
            }
        }));

        if (response.meta.requestStatus === 'rejected') {
            return thunkApi.rejectWithValue({ status: response.payload?.toString() ?? '' });
        }

        const resolvedResponse = response.payload;
        if (!(resolvedResponse.ok && resolvedResponse.text)) {
            return thunkApi.rejectWithValue({ status: resolvedResponse.status?.toString() ?? '' });
        }
        return resolvedResponse.text;
    }
);

export const resetUserPassword = createAsyncThunk(`resetUserPassword`,
    async (params, thunkApi) => {
        const response = await thunkApi.dispatch(noUserBaseFetchFromServer({
            input: `${apiUrl}/portal/users/resetPassword`,
            init: {
                method: 'POST',
                headers: new Headers({
                    "Content-Type": "application/json"
                }),
                body: JSON.stringify(params.body)
            }
        }));

        if (response.meta.requestStatus === 'rejected') {
            return thunkApi.rejectWithValue({ status: response.payload?.toString() ?? '' });
        }

        const resolvedResponse = response.payload;
        if (!(resolvedResponse.ok && resolvedResponse.text)) {
            return thunkApi.rejectWithValue({ status: resolvedResponse.status?.toString() ?? '' });
        }

        return resolvedResponse.text;
    }
);

export const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(changeUserPassword.fulfilled, (state, action) => {
            state.changePasswordRequestComplete = true;
        });
        builder.addCase(changeUserPassword.rejected, (state, action) => {
            state.changePasswordRequestComplete = false;
        });
        builder.addCase(changeUserPassword.pending, (state, action) => {
            state.changePasswordRequestComplete = false;
        });
        builder.addCase(resetUserPassword.fulfilled, (state, action) => {
            if (action.payload.length > 0) {
                state.dataPasswordReset = JSON.parse(action.payload);
            }
            state.resetPasswordRequestComplete = true;
        });
        builder.addCase(resetUserPassword.rejected, (state, action) => {
            state.resetPasswordRequestComplete = false;
        });
        builder.addCase(resetUserPassword.pending, (state, action) => {
            state.resetPasswordRequestComplete = false;
        });
    }
});

export const getChangePasswordRequestComplete = (state) => state.users.changePasswordRequestComplete;
export const getResetPasswordRequestComplete = (state) => state.users.resetPasswordRequestComplete;
export const getDataPasswordReset = (state) => state.users.dataPasswordReset;

export default usersSlice.reducer;
