import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    method: '',
    status: '', //pending, success, error
    header: '',
    response: '',
    objectName: ''
};

export const actionStatusSlice = createSlice({
    name: 'actionStatus',
    initialState,
    reducers: {
        setActionStatus: (state, action) => {

            Object.assign(state, action.payload);
        },
        resetActionStatus: (state) => {
            Object.assign(state, initialState);
        }
    }
});

export const {setActionStatus, resetActionStatus} = actionStatusSlice.actions;

export const getActionStatus = (state) => state.actionStatus;
export const getStatus = (state) => state.actionStatus.status;

export default actionStatusSlice.reducer;



