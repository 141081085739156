import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiUrl, baseFetchFromServer } from "../AppSlice";

const initialState = {
    allGroups: [],
    searchedGroups: [],
    fetchGroupsRequestComplete: false,
    searchGroupsRequestComplete: false,
};

export const fetchAllGroups = createAsyncThunk(`fetchAllGroups`,
    async (params, thunkApi) => {

        const response = await thunkApi.dispatch(baseFetchFromServer({
            input: `${apiUrl}/portal/groups?asHierarchy=true&parentGroupId=0`,
            init: {
                method: 'GET',
                headers: new Headers({
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            }
        }));
        if (response.meta.requestStatus === 'rejected') {
            return thunkApi.rejectWithValue({ status: response.payload?.toString() ?? '' });
        }
        const resolvedResponse = response.payload;
        if (!(resolvedResponse.ok && resolvedResponse.text)) {
            return thunkApi.rejectWithValue({ status: resolvedResponse.status?.toString() ?? '' });
        }

        return resolvedResponse.text;
    }
);

export const searchGroups = createAsyncThunk(`searchGroups`,
    async (params, thunkApi) => {
        const response = await thunkApi.dispatch(baseFetchFromServer({
            input: `${apiUrl}/portal/groups?asHierarchy=true&query=${params.query}`,
            init: {
                method: 'GET',
                headers: new Headers({
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            }
        }));
        if (response.meta.requestStatus === 'rejected') {
            return thunkApi.rejectWithValue({ status: response.payload?.toString() ?? '' });
        }
        const resolvedResponse = response.payload;
        if (!(resolvedResponse.ok && resolvedResponse.text)) {
            return thunkApi.rejectWithValue({ status: resolvedResponse.status?.toString() ?? '' });
        }


        return resolvedResponse.text;
    }
);

export const groupsSlice = createSlice({
    name: "groups",
    initialState,
    reducers: {
        setActive: (state, action) => {
            if (action.payload.parentActive) {
                const rootParentGroupIndex = state.allGroups.findIndex(group => group.active);
                const rootParentGroup = state.allGroups[rootParentGroupIndex];
                const childGroup = rootParentGroup?.childGroup?.find(group => group.id === action.payload.id);

                if (childGroup !== undefined) childGroup.active = true;

            } else {
                let activeGroups = state.allGroups.filter((group) => group.active);

                activeGroups.forEach((group) => {
                    if (group.childGroups)
                        group.childGroups.forEach(childGroup => {
                            childGroup.parentActive = false;
                        });

                    group.active = false;
                });

                let targetGroup = state.allGroups.find(
                    (group) => group.id === action.payload.id
                );

                if (targetGroup !== undefined) {
                    targetGroup.active = true;

                    const setAllChildrenToActive = (childGroups) => {
                        childGroups.forEach(child => {
                            child.active = false;
                            child.parentActive = true;

                            if (child.childGroups) {
                                setAllChildrenToActive(child.childGroups);
                            }
                        });
                    }

                    if (targetGroup.childGroups)
                        setAllChildrenToActive(targetGroup.childGroups);
                }
            }
        },
        setSearchedActive: (state, action) => {
            if (action.payload.parentActive) {
                const rootParentGroupIndex = state.searchedGroups.findIndex(group => group.active);
                const rootParentGroup = state.searchedGroups[rootParentGroupIndex];
                const childGroup = rootParentGroup?.childGroup?.find(group => group.id === action.payload.id);

                if (childGroup !== undefined) childGroup.active = true;

            } else {
                let activeGroups = state.searchedGroups.filter((group) => group.active);

                activeGroups.forEach((group) => {
                    if (group.childGroups)
                        group.childGroups.forEach(childGroup => {
                            childGroup.parentActive = false;
                        });

                    group.active = false;
                });

                let targetGroup = state.searchedGroups.find(
                    (group) => group.id === action.payload.id
                );

                if (targetGroup !== undefined) {
                    targetGroup.active = true;

                    if (targetGroup.childGroups)
                        targetGroup.childGroups.forEach(childGroup => {
                            childGroup.active = false;
                            childGroup.parentActive = true;
                        });
                }
            }
        },
        clearSearch: (state, action) => {
            state.searchedGroups = [];
            state.searchGroupsRequestComplete = false;
        }

    },
    extraReducers: builder => {
        builder.addCase(fetchAllGroups.pending, (state, action) => {
            state.fetchGroupsRequestComplete = false;
        });
        builder.addCase(fetchAllGroups.fulfilled, (state, action) => {

            if (action.payload.length > 0) {
                const js = JSON.parse(action.payload);
                if (js.count > 0) {
                    let data = js.data;
                    data.unshift({ id: 0, displayName: "All Desks", active: true }, { id: 1, displayName: "Ungrouped Desks" })
                    Object.assign(state.allGroups, data);
                }
            }
            state.fetchGroupsRequestComplete = true;
        });
        builder.addCase(fetchAllGroups.rejected, (state, action) => {
            state.fetchGroupsRequestComplete = true;
        });
        builder.addCase(searchGroups.pending, (state, action) => {
            state.searchGroupsRequestComplete = false;
        });
        builder.addCase(searchGroups.fulfilled, (state, action) => {

            if (action.payload.length > 0) {
                const js = JSON.parse(action.payload);
                Object.assign(state.searchedGroups, js.data);
            } else {
                Object.assign(state.searchedGroups, []);
            }
            state.searchGroupsRequestComplete = true;
        });
        builder.addCase(searchGroups.rejected, (state, action) => {
            state.searchGroupsRequestComplete = true;
        });
    }
});

export const { setActive, setSearchedActive, clearSearch } = groupsSlice.actions;

export const getAllGroups = (state) => state.groups.allGroups;
export const getFetchGroupsRequestComplete = (state) => state.groups.fetchGroupsRequestComplete;
export const getSearchedGroups = (state) => state.groups.searchedGroups;
export const getSearchGroupsRequestComplete = (state) => state.groups.searchGroupsRequestComplete;

export default groupsSlice.reducer;
