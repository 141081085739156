import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiUrl, baseFetchFromServer } from "../AppSlice";

const initialState = {
    profiles: [],
    isFetchProfilesComplete: false,
};

export const fetchProfiles = createAsyncThunk(`fetchProfiles`,
    async (params, thunkApi) => {

        const response = await thunkApi.dispatch(baseFetchFromServer({
            input: `${apiUrl}/portal/profiles?resources=desk`,
            init: {
                method: 'GET',
                headers: new Headers({
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            }
        }));
        if (response.meta.requestStatus === 'rejected') {
            return thunkApi.rejectWithValue({ status: response.payload?.toString() ?? '' });
        }
        const resolvedResponse = response.payload;
        if (!(resolvedResponse.ok && resolvedResponse.text)) {
            return thunkApi.rejectWithValue({ status: resolvedResponse.status?.toString() ?? '' });
        }

        return resolvedResponse.text;
    }
);

export const profilesSlice = createSlice({
    name: "profiles",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchProfiles.pending, (state, action) => {
            state.isFetchProfilesComplete = false;
        });
        builder.addCase(fetchProfiles.fulfilled, (state, action) => {
            if (action.payload.length > 0) {
                const js = JSON.parse(action.payload);
                state.profiles = js.data;
            }
            state.isFetchProfilesComplete = true;
        });
        builder.addCase(fetchProfiles.rejected, (state, action) => {
            state.isFetchProfilesComplete = true;
        });
    }
});

export const getProfiles = (state) => state.profiles.profiles;
export const getFetchProfilesComplete = (state) => state.profiles.isFetchProfilesComplete;

export default profilesSlice.reducer;
