import { FC } from "react";
import GroupItem from "./GroupItem";

import './Groups.scss';
import { Group } from "../../types";

interface GroupAllProps {
    groups: Group[],
    onActive: (group: Group) => void;
}

const GroupAll: FC<GroupAllProps> = ({ groups, onActive }) => {

    const groupsContent = (groups: Group[]) => {
        if (groups.length > 0) {
            return groups.map((item, index) => <GroupItem key={index} lvl={0}
                group={item}
                onActive={onActive} />);
        } else {
            return "No items to show";
        }
    };

    return <>
        <div className={"tree-nav page-props.groups"}>
            <div>
                <div className={"groups-container position-relative"}>
                    <div className={"scroll-container"}>
                        <ul className={"main-group"}>
                            {groupsContent(groups)}
                        </ul>
                    </div>
                </div>
            </div>
        </div>

    </>;
};

export default GroupAll;