import './SideNav.scss';
import { NavLink } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { IconNavDashboard, IconNavDesks } from '../../assets/icons';

function SideNav() {
    return (
        <div>
            <nav className={"main-nav"}>
                <div>
                    <OverlayTrigger
                        placement={'right'}
                        key={"My Bookings"}
                        overlay={<Tooltip className={"sidenav-tooltip"} id="button-tooltip">My Bookings</Tooltip>}
                    >
                        <NavLink to="/">
                            <IconNavDashboard />
                            <span>My Bookings</span>
                        </NavLink>
                    </OverlayTrigger>
                </div>
                {/* <div>
                    <OverlayTrigger
                        placement={'right'}
                        key={"Rooms"}
                        overlay={TooltipReserva}
                        popperConfig={{ title: "Rooms" }}
                    >
                        <NavLink to="/rooms">
                            <IconNavRooms />
                            <span>Rooms</span>
                        </NavLink>
                    </OverlayTrigger>
                </div> */}
                <div>
                    <OverlayTrigger
                        placement={'right'}
                        key={"Desks"}
                        overlay={<Tooltip className={"sidenav-tooltip"} id="button-tooltip">Desks</Tooltip>}
                    >
                        <NavLink to="/desks">
                            <IconNavDesks />
                            <span>Desks</span>
                        </NavLink>
                    </OverlayTrigger>
                </div>
            </nav>
        </div>
    );
}


export default SideNav;
