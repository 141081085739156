import { useState } from "react";
import { Form, Button, Container, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ReservaLogo } from "../../assets/logo";
import { ResetPasswordBackButton } from "../../assets/icons";
import './ForgotPassword.scss';

function ForgotPassword() {

    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [formSubmitted, setFormSubmitted] = useState(false);

    function handleSubmit() {
        // API request to trigger password reset goes here when available. For now, just show the 'You will receive instructions...' message.
        setFormSubmitted(true);
    }

    return (
        <div className={"reserva-forgot-password-container"}>
            <div className="reserva-forgot-password">
                <ReservaLogo className={"reserva-logo"} />
                <Container>
                    <Row className="info-header-row align-items-end">
                        <Col className="forgot-pw-arrow-icon" xs="2">
                            <ResetPasswordBackButton onClick={() => { navigate('/login', { replace: true }); }}
                            />
                        </Col>
                        <Col md="auto">
                            <span className="info-header">Forgot your password</span>
                        </Col>
                    </Row>
                    <Row className="info-row align-items-center">
                        <span hidden={formSubmitted} className="info">To receive instructions on how to reset your password, please enter your email address below.</span>
                        <span hidden={!formSubmitted} className="info">You will receive instructions on how to reset your password if an account with this email address exists.</span>
                    </Row>
                </Container>
                <Form hidden={formSubmitted} onSubmit={handleSubmit}>
                    <Form.Group controlId="email">
                        <Form.Control
                            autoFocus
                            type="email"
                            value={email}
                            placeholder={"Email"}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Form.Group>
                    <Button variant="primary" type="submit" disabled={email.length === 0}>
                        Restore Password
                    </Button>
                </Form>
            </div>
        </div>
    );
}

export default ForgotPassword; 