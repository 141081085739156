import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiUrl, baseFetchFromServer } from "../AppSlice";

const initialState = {
    allDesks: [],
    fetchAllDesksRequestComplete: false,
};

export const fetchAllDesks = createAsyncThunk(`fetchAllDesks`,
    async (params, thunkApi) => {

        var endpoint = `${apiUrl}/portal/desks`;

        if (params.includeBookings) {
            endpoint = `${endpoint}?includeBookings=${params.includeBookings}`
        }

        const response = await thunkApi.dispatch(baseFetchFromServer({
            input: endpoint,
            init: {
                method: 'GET',
                headers: new Headers({
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            }
        }));
        if (response.meta.requestStatus === 'rejected') {
            return thunkApi.rejectWithValue({ status: response.payload?.toString() ?? '' });
        }
        const resolvedResponse = response.payload;
        if (!(resolvedResponse.ok && resolvedResponse.text)) {
            return thunkApi.rejectWithValue({ status: resolvedResponse.status?.toString() ?? '' });
        }

        return resolvedResponse.text;
    }
);

export const fetchDesksFileteredByGroup = createAsyncThunk(`fetchDesksFileteredByGroup`,
    async (params, thunkApi) => {

        var endpoint = `${apiUrl}/portal/desks?parentGroupId=${params.parentGroupId}`;

        if (params.includeBookings) {
            endpoint = `${endpoint}&includeBookings=${params.includeBookings}`
        }

        if (params.includeInChildren) {
            endpoint = `${endpoint}&childGroups=${params.includeInChildren}`
        }

        const response = await thunkApi.dispatch(baseFetchFromServer({
            input: endpoint,
            init: {
                method: 'GET',
                headers: new Headers({
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            }
        }));
        if (response.meta.requestStatus === 'rejected') {
            return thunkApi.rejectWithValue({ status: response.payload?.toString() ?? '' });
        }
        const resolvedResponse = response.payload;
        if (!(resolvedResponse.ok && resolvedResponse.text)) {
            return thunkApi.rejectWithValue({ status: resolvedResponse.status?.toString() ?? '' });
        }

        return resolvedResponse.text;
    }
);

export const fetchDeskById = createAsyncThunk(`fetchDeskById`,
    async (params, thunkApi) => {

        const response = await thunkApi.dispatch(baseFetchFromServer({
            input: `${apiUrl}/portal/desks/${params.id}`,
            init: {
                method: 'GET',
                headers: new Headers({
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            }
        }));
        if (response.meta.requestStatus === 'rejected') {
            return thunkApi.rejectWithValue({ status: response.payload?.toString() ?? '' });
        }
        const resolvedResponse = response.payload;
        if (!(resolvedResponse.ok && resolvedResponse.text)) {
            return thunkApi.rejectWithValue({ status: resolvedResponse.status?.toString() ?? '' });
        }

        return resolvedResponse.text;
    }
);

export const desksSlice = createSlice({
    name: "desks",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchAllDesks.pending, (state, action) => {
            state.fetchAllDesksRequestComplete = false;
        });
        builder.addCase(fetchAllDesks.fulfilled, (state, action) => {

            if (action.payload.length > 0) {
                const js = JSON.parse(action.payload);
                if (js.count > 0) {
                    let data = js.data;
                    Object.assign(state.allDesks, data);
                }
            }
            state.fetchAllDesksRequestComplete = true;
        });
        builder.addCase(fetchAllDesks.rejected, (state, action) => {
            state.fetchAllDesksRequestComplete = true;
        });
        builder.addCase(fetchDesksFileteredByGroup.pending, (state, action) => {
            state.fetchAllDesksRequestComplete = false;
        });
        builder.addCase(fetchDesksFileteredByGroup.fulfilled, (state, action) => {

            if (action.payload.length > 0) {
                const js = JSON.parse(action.payload);
                if (js.count > 0) {
                    let data = js.data;
                    Object.assign(state.allDesks, data);
                }
            }
            state.fetchAllDesksRequestComplete = true;
        });
        builder.addCase(fetchDesksFileteredByGroup.rejected, (state, action) => {
            state.fetchAllDesksRequestComplete = true;
        });
    }
});

export const getAllDesks = (state) => state.desks.allDesks
export const getFetchAllDesksRequestComplete = (state) => state.desks.getAllDesksRequestComplete;

export default desksSlice.reducer;
