import './App.scss';
import Main from './components/Main/Main';
import { HashRouter, Routes, Route } from "react-router-dom";
import Desks from './pages/Desks/Desks';
import MyBookings from './pages/MyBookings/MyBookings';
import Rooms from './pages/Rooms/Rooms';
import Login from './pages/Login/Login';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import { AuthProvider, RequireAuth } from './Auth';
import ChangePassword from './pages/ChangePassword/ChangePassword.js';
import { RequireLicence } from './pages/LicenceVerification/LicenceVerification';

function App() {
  return (
    <AuthProvider>
      <HashRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/changePassword" element={<ChangePassword />} />
          <Route path="/forgotPassword" element={<ForgotPassword />} />
          <Route element={
            <RequireAuth>
              <RequireLicence>
                <Main />
              </RequireLicence>
            </RequireAuth>
          }>
            <Route path="/" element={<MyBookings />} />
            <Route path="/rooms" element={<Rooms />} />
            <Route path="/desks" element={<Desks />} />
          </Route>
        </Routes>
      </HashRouter>
    </AuthProvider>
  );
}

export default App;
