import { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { changeUserPassword } from "../../store/Users";
import { ReservaLogo } from "../../assets/logo";
import './ChangePassword.scss';

function ChangePassword() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [currentPassword, setCurrentPassword] = useState(location.state?.password);
    const [newPassword, setPassword] = useState("");
    const [confirmNewPassword, setConfirmPassword] = useState("");

    // Passwords MUST NOT match this regex in order to be considered valid.
    var validationRegex = /^(.{0,7}|[^a-z]{1,}|[^A-Z]{1,}|[^\d]{1,}|[^\W]{1,})$|[\s]/;

    function passwordRulesValidation() {
        return !newPassword.match(validationRegex);
    }

    function passwordMatch() {
        return confirmNewPassword.match(newPassword) && confirmNewPassword.length > 0;
    }

    function handleSubmit(event) {
        event.preventDefault();

        dispatch(changeUserPassword(
            {
                body:
                {
                    currentPassword: currentPassword,
                    newPassword: newPassword
                }
            }))
            // TODO: need to catch error or unsuccessful result
            .then(() => {
                window.localStorage.removeItem("requirePasswordChange");
                const to = location.state?.from ? location.state.from : '/';
                navigate(to, { replace: true });
            });
    }

    return (
        <div className={"reserva-change-password-container"}>
            <div className="reserva-change-password">
                <ReservaLogo />
                <div className="form-description">
                    <h1>{location.state?.requireChange ? "You are required to change your password." : "Change your password."}</h1>
                </div>
                <Form onSubmit={handleSubmit}>
                    {!location.state?.password ? (
                        <Form.Group size="lg" controlId="currentPassword">
                            <Form.Control
                                autoFocus
                                type="password"
                                value={currentPassword}
                                placeholder={"Current Password"}
                                onChange={(e) => setCurrentPassword(e.target.value)}
                            />
                        </Form.Group>
                    ) : null}
                    <Form.Group size="lg" controlId="newPassword">
                        <Form.Control
                            autoFocus
                            type="password"
                            value={newPassword}
                            placeholder={"New Password"}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group size="lg" controlId="confirmNewPassword">
                        <Form.Control
                            type="password"
                            value={confirmNewPassword}
                            placeholder={"Confirm Password"}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                    </Form.Group>
                    <table>
                        <tr>
                            <span className="reserva-password-rules" hidden={passwordRulesValidation()}>
                                Must contain a minimum of 8 characters and at least 1 upper case, 1 number and 1 special character.
                            </span>
                        </tr>
                        <tr>
                            <span className="reserva-password-rules" hidden={passwordMatch()}>
                                Passwords must match.
                            </span>
                        </tr>
                    </table>
                    <Button variant="primary" size="lg" type="submit" disabled={!(passwordRulesValidation() && passwordMatch() && currentPassword)}>
                        Save Password
                    </Button>
                </Form>
            </div>
        </div>
    );
}

export default ChangePassword;