import './DeskBookingControls.scss';
import { Form, Row, Col } from 'react-bootstrap';
import { useCallback, useEffect, useState } from 'react';
import ToggleSwitch from '../SwitchButton/SwitchButton';
import { BookingSettings } from '../../types';

interface DeskBookingControlsProps {
    setSettings: (option: string, value: any) => void,
    onFilterReset: () => void,
    resetFlag: boolean,
    defaultSettings: BookingSettings,
    currentSettings: BookingSettings;
}

const DeskBookingControls: React.FC<DeskBookingControlsProps> = (props: DeskBookingControlsProps) => {

    const [allDayEnabled, setAllDayEnabled] = useState(props.defaultSettings.allDay);
    const [date, setDate] = useState(props.defaultSettings.date);
    const [startTime, setStartTime] = useState(props.defaultSettings.startTime);
    const [endTime, setEndTime] = useState(props.defaultSettings.endTime);

    const handleChange = useCallback((setting: string, value: any) => {
        props.setSettings(setting, value);
    }, [props]);

    const resetSettings = useCallback(() => {
        setDate(props.defaultSettings.date);
        setStartTime(props.defaultSettings.startTime);
        setEndTime(props.defaultSettings.endTime);
        setAllDayEnabled(props.defaultSettings.allDay);

        handleChange("date", props.defaultSettings.date);
        handleChange("startTime", props.defaultSettings.startTime);
        handleChange("endTime", props.defaultSettings.endTime);
        handleChange("allDay", props.defaultSettings.allDay);
    }, [props, handleChange]);

    // Reset filter after booking
    useEffect(() => {
        if (props.resetFlag) {
            resetSettings();
            props.onFilterReset();
        }
    }, [props, resetSettings]);

    return (<>
        <Form className={"control-form"}>
            <Row>
                <Col xs={2}>
                    <Form.Label>Date</Form.Label>
                    <Form.Control
                        type="date"
                        placeholder="Date"
                        onChange={e => {
                            setDate(e.target.value);
                            handleChange("date", e.target.value);
                        }}
                        value={date}
                    />
                </Col>
                <Col xs={2}>
                    <Form.Label>Start Time</Form.Label>
                    <Form.Control
                        type="time"
                        placeholder="Start Time"
                        onChange={e => {
                            setStartTime(e.target.value);
                            handleChange("startTime", e.target.value);
                        }}
                        value={startTime}
                        disabled={allDayEnabled}
                    />
                </Col>
                <Col xs={2}>
                    <Form.Label>End Time</Form.Label>
                    <Form.Control
                        type="time"
                        placeholder="End Time"
                        onChange={e => {
                            setEndTime(e.target.value);
                            handleChange("endTime", e.target.value);
                        }}
                        value={endTime}
                        disabled={allDayEnabled}
                    />
                </Col>
                <Col xs={2}>
                    <ToggleSwitch
                        changeHandler={() => {
                            setAllDayEnabled(!allDayEnabled);
                            handleChange("allDay", !allDayEnabled);
                        }}
                        isActive={allDayEnabled}
                    />
                    <span className={"all-day-label"}>All Day</span>
                </Col>
            </Row>
        </Form>
    </>);
};

export default DeskBookingControls;
