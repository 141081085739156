import { createContext, useState, useEffect, FC, ReactNode } from 'react';
import { useMediaQuery } from 'react-responsive';

interface ViewportContextProps {
    isMobile: boolean;
    viewportWidth: number;
}

const initialContext: ViewportContextProps = {
    isMobile: false,
    viewportWidth: window.innerWidth,
};

export const ViewportContext = createContext<ViewportContextProps>(initialContext);

interface ViewportProviderProps {
    children: ReactNode;
}

export const ViewportProvider: FC<ViewportProviderProps> = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 1024 });
    const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setViewportWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const contextValue: ViewportContextProps = {
        isMobile,
        viewportWidth,
    };

    return (
        <ViewportContext.Provider value={contextValue}>{children}</ViewportContext.Provider>
    );
};
