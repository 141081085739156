import { FC } from "react";
import {
    ArrowRightWhiteIcon, ArrowRightGreyIcon
} from "../../assets/icons";

import './Groups.scss';
import { Group } from "../../types";

interface GroupItemProps {
    group: Group;
    onActive: (group: Group) => void;
    lvl: number;
}

const GroupItem: FC<GroupItemProps> = ({ group, lvl, onActive }) => {
    const activeState = group.active || group.parentActive ? "active" : "inactive";
    const childGroupsLoaded = group.childGroups && group.childGroups.length > 0 ? "" : " no-child";

    const headerNameCutoffThreshold = 30;

    // const groupsDropdownOptions = [
    //     { name: "Make Default", value: "makeDefault" },
    //     { name: "Show Map", value: "showMap" },
    // ];

    // const actionFunction = (param: string) => {
    //     if (param === "makeDefault") {
    //     } else if (param === "showMap") { }
    // };

    const formatHeader = (displayName: string) =>
        displayName.length > headerNameCutoffThreshold ?
            displayName.substring(0, headerNameCutoffThreshold).concat("...") :
            displayName;

    return (
        <li className={activeState + childGroupsLoaded}>
            <div className={"d-flex"} onClick={() => onActive(group)}>
                {group.childGroups && group.childGroups.length ?
                    <div className={"has-children-icon " + activeState}>
                        {activeState === "active" ?
                            <ArrowRightWhiteIcon className={"children-dropdown-icon"} /> : <ArrowRightGreyIcon className={"children-dropdown-icon"} />}
                    </div> : <div className="no-children-indent" />}

                <div>
                    <label>{formatHeader(group.displayName)}</label>
                    {/* {props.allParents?.length > 0 &&
                        <p>{props.allParents.map(par => par.name).join("   >   ")}</p>
                    } */}
                </div>

                {/* Three dots menu for "show map" and "make default" options. Hide for now until implemented in future release. */}
                {/* {group.id !== "all_desks" && group.id !== "ungrouped_desks" &&
                    <Dropdown className={"no-arrow"}>
                        <Dropdown.Toggle>
                            <OptionsIcon />
                        </Dropdown.Toggle>

                        <Dropdown.Menu style={{ margin: 0 }}>
                            {groupsDropdownOptions.filter((item) => !(lvl === 4 && item.value === "subGroupFunction")).map((item) =>
                                <Dropdown.Item key={id + item.value}
                                    onClick={() => actionFunction(item.value)}>{item.name}</Dropdown.Item>
                            )}
                        </Dropdown.Menu>
                    </Dropdown>} */}
            </div>
            <ul>
                {group.childGroups && group.childGroups.map((item) =>
                    <GroupItem group={item} key={item.id}
                        onActive={onActive} lvl={lvl + 1} />)}
            </ul>
        </li>
    );
};

export default GroupItem;