import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { setActionStatus } from "./store/ActionStatus";


const initialState = {
    accessToken: '',
    refreshToken: '',
    tokenExpires: 0,
    requestStatus: 'init',
    isAuthenticated: false,
    authorizationStatus: null,
    cookieAuth: null,
    idpOptions: [],
    rejectedFrom: '',
    lastSignInAttemptType: ''
};

export const apiUrl = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_URL : window.API_URL;

let abortController = new AbortController();
let resolveAuthPromise
let rejectAuthPromise
let authPromise

function NewAuthPromise() {
    abortController = new AbortController();
    resolveAuthPromise = null;
    rejectAuthPromise = null;
    return new Promise((resolve, reject) => {
        resolveAuthPromise = resolve;
        rejectAuthPromise = reject;
    });
}

export const noUserBaseFetchFromServer = createAsyncThunk('base-url-fetch',
    async (params, thunkApi) => {
        let token = window.localStorage.getItem("clientAccessToken");
        let headers = new Headers(params.init?.headers);

        if (token)
            headers.set('Authorization', `Bearer ${token}`);

        const response = await fetch(params.input, {
            ...params.init,
            headers: headers,
            signal: abortController.signal
        });

        let result = {}

        result = {
            ok: response.ok,
            status: response.status,
            text: await response.text()
        }

        if (response.ok)
            return thunkApi.fulfillWithValue(result)
        else
            return thunkApi.rejectWithValue(result)

    }
)

function wait(delay) {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve();
        }, delay);
    })
}

const baseFetch = (params, thunk, retries = 0) => {
    return authPromise.then(() => {

        let token = thunk.getState().app.accessToken;
        let headers = new Headers(params.init?.headers);

        if (token)
            headers.set('Authorization', `Bearer ${token}`);

        return fetch(params.input, {
            ...params.init,
            headers: headers,
            credentials: 'include',
            signal: abortController.signal
        }).catch(r => {

            return wait(100).then(() => {
                if (retries === 0)
                    return {
                        status: 0,
                        ok: false,
                        text: () => "aborted"
                    }
                else
                    return baseFetch(params, retries - 1);
            })
        });
    })

}

const jsonRegex = /json/;
export const baseFetchFromServer = createAsyncThunk('base-url-fetch',
    async (params, thunkApi) => {
        const response = await baseFetch(params, thunkApi, 3);

        thunkApi.dispatch(setIdpOptions(parseIdpOptions(response)));

        let result = {};
        result = {
            ok: response.ok,
            status: response.status,
            text: await response.text()
        }

        if (result.status === 401) {
            abortController.abort();
            thunkApi.dispatch(resetAccessToken());
        }

        const contentType = response.headers.get('Content-Type');
        if (contentType && jsonRegex.test(contentType)) {
            result = {
                ...result,
                json: JSON.parse(result.text)
            }
        }
        if (response.ok)
            return thunkApi.fulfillWithValue(result)
        else
            return thunkApi.rejectWithValue(result)
    }
);

export const fetchFileFromServer = createAsyncThunk('base-url-fetch',
    async (params, thunkApi) => {
        let token = thunkApi.getState().app.accessToken;
        let headers = new Headers(params.init?.headers);

        if (token)
            headers.set('Authorization', `Bearer ${token}`);

        const response = await fetch(params.input, {
            ...params.init,
            headers: headers,
            credentials: 'include',
            signal: abortController.signal
        });
        const blob = await response.blob();
        const filename = response.headers.get('Content-Disposition')?.split('filename=')[1]?.split(';')[0]?.replace(/^"|"$/g, '');
        const filenameEncoded = response.headers.get('Content-Disposition')?.split('filename*=UTF-8' / '/')[1]?.replace(/^"|"$/g, '');
        const contentType = response.headers.get('Content-Type');

        let result = {};
        result = {
            ok: response.ok,
            status: response.status,
            filename: filename,
            filenameEncoded: filenameEncoded,
            size: blob.size,
            contentType: contentType,
            blobUrl: URL.createObjectURL(blob)
        }
        if (response.ok)
            return thunkApi.fulfillWithValue(result)
        else
            return thunkApi.rejectWithValue(result)
    }
);


export const appSlice = createSlice({
    name: 'connector',
    initialState,
    reducers: {
        setAuthTokens: (state, action) => {
            Object.assign(state, { ...state, ...action.payload });

            if (state.isAuthenticated && state.authorizationStatus) {
                if (!authPromise)
                    authPromise = NewAuthPromise();
                resolveAuthPromise();
            }
        },
        setAuthorizationStatus: (state, action) => {
            state.authorizationStatus = action.payload;
        },
        setRejectedFrom: (state, action) => {
            state.rejectedFrom = action.payload;
        },
        setLastSignInAttemptType: (state, action) => {
            state.lastSignInAttemptType = action.payload;
        },
        setIdpOptions: (state, action) => {
            const currentState = current(state);
            if (action.payload && JSON.stringify(currentState.idpOptions) !== JSON.stringify(action.payload)) {
                state.idpOptions = action.payload;
            }
        },
        resetAuth: (state, action) => {
            Object.assign(state, {
                ...action.payload,
                accessToken: '',
                refreshToken: '',
                tokenExpires: '',
                isAuthenticated: false,
            })
            authPromise = NewAuthPromise();
        },
        resetAccessToken: (state) => {
            Object.assign(state, {
                accessToken: '',
                tokenExpires: '',
                authorizationStatus: false,
                requestStatus: ''
            })

            abortController = new AbortController();
            if (rejectAuthPromise)
                rejectAuthPromise();
            authPromise = NewAuthPromise();
        }
    },
});

export async function returnAction(thunkApi, response) {
    let isError = null;
    const resolvedResponse = response.payload;
    if (response.meta.requestStatus === "rejected" || !resolvedResponse.ok)
        if (resolvedResponse?.json) {
            isError = {
                status: resolvedResponse.json.message ?? ""
            };
        } else {
            isError = {
                status: `Error server responded with: ${resolvedResponse.status?.toString()}`
            };
        }
    if (isError) {
        await thunkApi.dispatch(
            setActionStatus({
                method: response.meta.arg.init.method,
                status: "error",
                response: isError?.status
            })
        )
        return await thunkApi.rejectWithValue(isError);
    }
    await thunkApi.dispatch(
        setActionStatus({
            method: response.meta.arg.init.method,
            status: "success"
        })
    )


    return {
        ...(resolvedResponse.json ?? resolvedResponse),
        status: "success"

    };
}

export function parseIdpOptions(response) {
    let idpOptions = response.headers.get('X-Idp-Options')?.split(',');
    if (!idpOptions) {
        idpOptions = ['local'];
    }

    return idpOptions;
}

export let FetchResult = {
    ok: Boolean,
    status: Number | undefined,
    text: String | undefined
}
export const { setAuthTokens, setAuthorizationStatus, setIdpOptions, setRejectedFrom, setLastSignInAttemptType, resetAuth, resetAccessToken } = appSlice.actions

export const getAuthorizationState = (state) => state.app;

export const getAuthorizationStatus = (state) => state.app.authorizationStatus;

export const getRejectedFrom = (state) => state.app.rejectedFrom;

export const getLastSignInAttemptType = (state) => state.app.lastSignInAttemptType;

export const getAccessToken = (state) => state.app.accessToken;

export const getRefreshToken = (state) => state.app.refreshToken;

export const getIdpOptions = (state) => state.app.idpOptions;

export default appSlice.reducer;