import { Modal } from "react-bootstrap";
import './AppModal.scss';
import { FC, ReactNode } from "react";

interface AppModalProps {
    show: boolean,
    header: ReactNode,
    content: ReactNode,
    footer: ReactNode,
    size?: "lg" | "sm" | "xl" | undefined;
}

const AppModal: FC<AppModalProps> = ({ show, header, content, footer, size }) => {

    const modalSize = size ? size : "lg";

    return (
        <Modal
            size={modalSize}
            show={show}
            centered
            scrollable={true}
        >
            <Modal.Header >
                <Modal.Title id="contained-modal-title-vcenter">
                    {header}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {content}
            </Modal.Body>
            <Modal.Footer>
                {footer}
            </Modal.Footer>
        </Modal>

    );
};


export default AppModal;
