import { useRef, useEffect, useState } from "react";
import { Button, FormControl, InputGroup } from "react-bootstrap";
import { SearchIcon } from "../../assets/icons";

import './Groups.scss';

import GroupAll from "./GroupAll";
// import { useAppDispatch, useAppSelector } from "../../hooks";
import { setActive, setSearchedActive, getAllGroups, fetchAllGroups, getSearchedGroups, searchGroups, clearSearch } from "../../store/groupsSlice";
import { useDispatch, useSelector } from "react-redux";

const Groups = ({ onGroupChange }) => {

    const allGroups = useSelector(getAllGroups);
    const searchedGroups = useSelector(getSearchedGroups);
    const dispatch = useDispatch();
    const searchInput = useRef(null);
    const [groupsToDisplay, setGroupsToDisplay] = useState([]);

    useEffect(() => {
        dispatch(fetchAllGroups());
    }, [dispatch]);

    function handleGroupActive(group) {
        if (searchInput.current.value.length > 0) {
            dispatch(setSearchedActive(group));
        } else {
            dispatch(setActive(group));
        }

        if (onGroupChange !== undefined) {
            onGroupChange(group.id);
        }
    }

    function handleSearchGroups(query) {
        if (query === "") {
            dispatch(clearSearch());

            if (onGroupChange !== undefined) {
                onGroupChange("");
            }
        } else {
            dispatch(searchGroups({ query: query })).then((resp) => {
                const response = JSON.parse(resp.payload);
                setGroupsToDisplay(response.data);
            });
        }
    }

    useEffect(() => {
        setGroupsToDisplay(searchInput.current?.value.length > 0 ? searchedGroups : allGroups);
    }, [searchedGroups, allGroups])

    return (
        <div className={"groups-wrapper"}>
            <div>
                <div className={"page-header flex-column"}>
                    <div className={"d-flex align-items-center"}>
                        <div className={"page-title"}>
                            <h1>Groups</h1>
                        </div>
                    </div>
                    <div className={"search-box"}>
                        <InputGroup>
                            <InputGroup.Text id="search-group">
                                <SearchIcon />
                            </InputGroup.Text>
                            <FormControl
                                placeholder="Search Groups"
                                aria-describedby="search-group"
                                className="search-group"
                                ref={searchInput}
                            />
                            <Button className={"btn-search"}
                                onClick={() => {
                                    handleSearchGroups(searchInput.current.value);
                                }}>
                                Go
                            </Button>
                        </InputGroup>
                    </div>
                </div>
                <GroupAll groups={groupsToDisplay} onActive={handleGroupActive} />
            </div>
        </div>

    );
};

export default Groups;