import './MobileCalendarOptions.scss'
import { Dropdown } from 'react-bootstrap';
import { useState } from "react";
import { ReactComponent as IconMonthView } from '../../assets/icons/icon_calendar_view_month.svg';
import { ReactComponent as IconWeekView } from '../../assets/icons/icon_calendar_view_week.svg';
import { ReactComponent as IconDayView } from '../../assets/icons/icon_calendar_view_day.svg';
import { ReactComponent as IconArrow } from '../../assets/icons/icon_arrow_blue.svg';


const MobileCalendarHeaderOptions = ({ onChangeView, initialView }) => {

    const [activeView, setActiveView] = useState(initialView)

    const handleChangeView = (newView) => {
        setActiveView(newView);
        onChangeView(newView);
    }

    const getActiveIcon = () => {
        switch (activeView) {
            case 3:
                return <IconMonthView />
            case 2:
                return <IconWeekView />
            case 1:
                return <IconDayView />
            default:
                return <IconMonthView />
        }
    }

    return (<div className={"mobile-calendar-options"}>
        <div className={"month"}><IconArrow /><span><b>April </b>2023</span><IconArrow /></div>
        <div className={"controls"}>
            <Dropdown>
                <Dropdown.Toggle id="dropdown-basic">
                    {getActiveIcon()}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => handleChangeView(3)}>Month <IconMonthView /></Dropdown.Item>
                    <Dropdown.Item onClick={() => handleChangeView(2)} >Week <IconWeekView /></Dropdown.Item>
                    <Dropdown.Item onClick={() => handleChangeView(1)} >Day <IconDayView /></Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            <Dropdown>
                <Dropdown.Toggle id="dropdown-basic">
                    Filter by
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item>Desks</Dropdown.Item>
                    <Dropdown.Item>Rooms</Dropdown.Item>
                    <Dropdown.Item>Resources</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>

    </div>);
}

export default MobileCalendarHeaderOptions;