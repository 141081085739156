import { BookingSettings, DeskProfile } from "../types";
import { isTimeBefore } from "./datetimeUtils";

// Check options and return array of error messages.
function validateSettings(settings: BookingSettings, profile?: DeskProfile): string[] {

    let errorMessages = [];

    if (settings) {
        // Check profile settings
        if (profile) {
            if (!profile.settings.extend)
                errorMessages.push("This booking cannot be edited as per it's resource's profile settings.");
        }

        if (!settings.allDay) {
            // If not all day...
            // Check if settings aren't empty.
            if (settings.date === "" || (settings.startTime === "" || settings.endTime === ""))
                errorMessages.push("Please fill out all options.");

            // Check if date is in the past.
            if (new Date(`${settings.date}T${settings.startTime}`) < new Date())
                errorMessages.push("You cannot book resources in the past.");

            // Check if start time is after end time.
            if (isTimeBefore(settings.endTime, settings.startTime))
                errorMessages.push("End time must be after start time.");

            // Check if start time and end time are the same.
            if (settings.endTime === settings.startTime)
                errorMessages.push("Start time must be different from end time.");
        } else {
            // If all day...
            // Check if date isn't empty.
            if (settings.date === "")
                errorMessages.push("Please select a date.");

            // Check if date is in the past.
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            if (new Date(`${settings.date}T00:00`) < today)
                errorMessages.push("You cannot book resources in the past.");
        }
    }

    return errorMessages;
}

export { validateSettings };